<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Person One Name (En)" vid="person_one_name_en" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="person_one_name_en">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.person_one_name') }} {{ $t('globalTrans.en') }}
                </template>
                <b-form-input
                  id="person_one_name_en"
                  v-model="formData.person_one_name_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Person One Name (Bn)" vid="person_one_name_name_bn" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="person_one_name_name_bn">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.person_one_name') }} {{ $t('globalTrans.bn') }}
                </template>
                <b-form-input
                  id="person_one_name_name_bn"
                  v-model="formData.person_one_name_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Person One Designation (En)" vid="person_one_designation_en" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="person_one_designation_en">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.person_one_designation') }} {{ $t('globalTrans.en') }}
                </template>
                <b-form-input
                  id="person_one_designation_en"
                  v-model="formData.person_one_designation_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Person One Designation (Bn)" vid="person_one_designation_name_bn" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="person_one_designation_name_bn">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.person_one_designation') }} {{ $t('globalTrans.bn') }}
                </template>
                <b-form-input
                  id="person_one_designation_name_bn"
                  v-model="formData.person_one_designation_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Person Two Name (En)" vid="person_two_name_en" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="person_two_name_en">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.person_two_name') }} {{ $t('globalTrans.en') }}
                </template>
                <b-form-input
                  id="person_two_name_en"
                  v-model="formData.person_two_name_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Person Two Name (Bn)" vid="person_two_name_name_bn" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="person_two_name_name_bn">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.person_two_name') }} {{ $t('globalTrans.bn') }}
                </template>
                <b-form-input
                  id="person_two_name_name_bn"
                  v-model="formData.person_two_name_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Person Two Designation (En)" vid="person_two_designation_en" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="person_two_designation_en">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.person_two_designation') }} {{ $t('globalTrans.en') }}
                </template>
                <b-form-input
                  id="person_two_designation_en"
                  v-model="formData.person_two_designation_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Person Two Designation (Bn)" vid="person_two_designation_name_bn" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="person_two_designation_name_bn">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.person_two_designation') }} {{ $t('globalTrans.bn') }}
                </template>
                <b-form-input
                  id="person_two_designation_name_bn"
                  v-model="formData.person_two_designation_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
           <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Designation Description (En)" vid="designation_description_en" rules="required" v-slot="{ valid, errors }">
              <b-form-group
                label-for="designation_description_en">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.designation_description') }} {{ $t('globalTrans.en') }} <span class="text-danger"> * </span>
                </template>
                <vue-editor
                  id="designation_description_en"
                  v-model="formData.designation_description_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <!-- <div class="invalid-feedback d-block" v-if="customErrors.designation_description_en.length">
                  {{ customErrors.designation_description_en[0] }}
                </div> -->
                <div class="invalid-feedback" :class="errors.length ? 'd-block': ''">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
           <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Designation Description (Bn)" vid="designation_description_bn" rules="required" v-slot="{ valid, errors }">
              <b-form-group
                label-for="designation_description_bn">
                <template v-slot:label>
                  {{ $t('bazarMonitoring.designation_description') }} {{ $t('globalTrans.bn') }} <span class="text-danger"> * </span>
                </template>
                <vue-editor
                  id="designation_description_bn"
                  v-model="formData.designation_description_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <!-- <div class="invalid-feedback d-block" v-if="customErrors.designation_description_bn.length">
                  {{ customErrors.designation_description_bn[0] }}
                </div> -->
                <div class="invalid-feedback" :class="errors.length ? 'd-block': ''">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import { designationInfoStore, designationInfoUpdate } from '../../api/routes'
import { VueEditor } from 'vue2-editor'
export default {
  name: 'Form',
  props: ['id'],
  components: { VueEditor },
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        person_one_name_en: '',
        person_one_name_bn: '',
        person_one_designation_en: '',
        person_one_designation_bn: '',
        person_two_name_en: '',
        person_two_name_bn: '',
        person_two_designation_en: '',
        person_two_designation_bn: '',
        designation_description_en: '',
        designation_description_bn: ''
      },
      customErrors: {
        designation_description_en: [],
        designation_description_bn: []
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
     this.formData = Object.assign({}, this.formData, tmp)
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(bazarMonitoringServiceBaseUrl, `${designationInfoUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(bazarMonitoringServiceBaseUrl, designationInfoStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('BazarMonitoringService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
